<template>
  <main class="establishment__form__container container py-2">
    <HomeIcon
      @click="() => $router.push({ name: 'auth-login' })"
      class="home__icon"
      size="40"
    />

    <b-row class="auth-inner">
      <b-col lg="6" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center">
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>

      <b-col lg="6" class="d-flex align-items-center auth-bg h-100 px-2 p-lg-5">
        <b-overlay :show="loading" rounded="sm" variant="transparent">
          <template #overlay>
            <div class="text-center">
              <b-spinner />
              <p class="mt-1">Registrando establecimiento</p>
            </div>
          </template>
          <form-wizard
            color="#7367F0"
            :title="null"
            :subtitle="null"
            layout="horizontal"
            :finish-button-text="loading ? 'Procesando...' : 'Confirmar'"
            next-button-text="Siguiente"
            back-button-text="Regresar"
            class="wizard-vertical"
            shape="square"
            step-size="sm"
            @on-complete="formSubmitted"
          >
            <!-- Datos del admin -->
            <tab-content title="Nombre" :before-change="validationForm">
              <validation-observer ref="accountRules" tag="form">
                <b-row>
                  <b-col cols="12">
                    <h2>Dueño del Establecimiento</h2>
                  </b-col>
                  <b-col cols="12" class="mb-2">
                    <h5 class="mb-0">Nombre del Dueño</h5>
                  </b-col>
                  <b-col>
                    <b-form-group label-for="v-ammount">
                      <validation-provider
                        #default="{ errors, valid }"
                        name="Nombre"
                        rules="required"
                      >
                        <b-input-group class="">
                          <b-form-input
                            id="v-ammount"
                            v-model="user.name"
                            :state="
                              errors.length > 0 ? false : valid ? true : null
                            "
                            placeholder="Nombre"
                            size="lg"
                            type="text"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" class="mb-2">
                    <h5 class="mb-0">Correo</h5>
                  </b-col>
                  <b-col>
                    <b-form-group label-for="v-loginFieldEmail">
                      <validation-provider
                        #default="{ errors, valid }"
                        name="El correo electrónico es necesario"
                        rules="required|email"
                      >
                        <b-input-group>
                          <b-form-input
                            id="v-loginFieldEmail"
                            v-model="user.email"
                            :state="
                              errors.length > 0 ? false : valid ? true : null
                            "
                            placeholder="JoseLopez@mywalleat.com"
                            size="lg"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" class="mb-2">
                    <h5 class="mb-0">Teléfono celular</h5>
                  </b-col>
                  <b-col>
                    <b-form-group label-for="v-loginFieldPhone">
                      <validation-provider
                        #default="{ errors, valid }"
                        name="El celular es necesario para validar su cuenta"
                        rules="required|numeric|phone_number"
                      >
                        <b-input-group>
                          <b-form-input
                            id="v-loginFieldPhone"
                            v-model="user.phone_number"
                            :state="
                              errors.length > 0 ? false : valid ? true : null
                            "
                            size="lg"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </validation-observer>
            </tab-content>

            <!-- Datos del establecimiento -->
            <tab-content
              title="Correo y número celular"
              :before-change="validationCelForm"
            >
              <validation-observer ref="accountCelRules" tag="form">
                <b-row>
                  <b-col cols="12">
                    <h2>Datos del negocio</h2>
                  </b-col>
                  <b-col sm="12">
                    <b-form-group label-for="v-loginFieldEmail">
                      <label class="h5"> Nombre del negocio </label>
                      <validation-provider
                        #default="{ errors }"
                        name="El nombre del negocio es necesario"
                        rules="required"
                      >
                        <b-input-group>
                          <b-form-input
                            id="v-loginFieldEmail"
                            v-model="user.establishment_attributes.name"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Nombre del negocio"
                            size="lg"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label-for="v-loginFieldPhone">
                      <label class="h5"> Tipo de Establecimiento </label>
                      <validation-provider
                        #default="{ errors }"
                        name="El Tipo de Establecimiento es necesario para validar su cuenta"
                        rules="required"
                      >
                        <b-input-group>
                          <b-form-select
                            v-model="
                              user.establishment_attributes.establishment_type
                            "
                            :options="establishment_types"
                          >
                          </b-form-select>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label-for="v-loginFieldPhone">
                      <label class="h5">
                        ¿Deseas registrarte como provedor de productos ó
                        servicios para otros negocios?
                      </label>
                      <validation-provider
                        #default="{ errors }"
                        name="Esta información es necesaria para crear su cuenta"
                        rules="required"
                      >
                        <b-input-group>
                          <b-form-select
                            v-model="user.establishment_attributes.is_supplier"
                            :options="is_supplier_options"
                          >
                          </b-form-select>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label-for="v-loginFieldPhone">
                      <label class="h5">
                        ¿Manejarás una única cuenta bancaria para recibir o
                        hacer pagos electrónicos como recargas de tiempo aire,
                        recepción de pagos de servicios, pagos con tarjeta
                        bancaria y transferencias?
                      </label>
                      <validation-provider
                        #default="{ errors }"
                        name="Es necesario rellenar el campo"
                        rules="required"
                      >
                        <b-input-group>
                          <b-form-select
                            v-model="
                              user.establishment_attributes.is_balance_shared
                            "
                            :options="[
                              { value: true, text: 'Si' },
                              { value: false, text: 'No' },
                            ]"
                          >
                          </b-form-select>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label-for="v-loginFieldEmail">
                      <label class="h5"> RFC (opcional) </label>
                      <validation-provider
                        #default="{ errors }"
                        name="El ID Fiscal es necesario"
                      >
                        <b-input-group>
                          <b-form-input
                            id="v-loginFieldEmail"
                            v-model="user.establishment_attributes.fiscal_id"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Opcional"
                            size="lg"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label-for="v-loginFieldEmail">
                      <label class="h5"> CLABE Interbancaria (opcional) </label>
                      <validation-provider
                        #default="{ errors }"
                        name="El ID Bancario es opcional"
                      >
                        <b-input-group>
                          <b-form-input
                            id="v-loginFieldEmail"
                            v-model="
                              user.establishment_attributes.bank_unique_id
                            "
                            :state="errors.length > 0 ? false : null"
                            placeholder="Opcional"
                            size="lg"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <label class="h5">
                      Número de WhatsApp para tu negocio
                    </label>
                    <b-form-group label-for="v-establishmentCelNumber">
                      <validation-provider
                        #default="{ errors, valid }"
                        name="necesario. Con el sus clientes podrán contactarlos diréctamente por Whatsapp. Si tu negocio no cuenta con uno, usa el número de celular con el que te registraste en el paso anterior, de lo contrario utiliza cualquier número registrado en Whatsapp"
                        rules="required|numeric|phone_number"
                      >
                        <b-input-group>
                          <b-form-input
                            id="v-establishmentCelNumber"
                            v-model="whatsappNumber"
                            :state="
                              errors.length > 0 ? false : valid ? true : null
                            "
                            placeholder="Whatsapp para que tus clientes te contacten"
                            size="lg"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!--
                    <b-col lg="4" md="6" sm="12">
                      <b-form-group label-for="v-loginFieldEmail">
                        <label class="h5">
                          ¿Cuál es el máximo que le fías a tus clientes?
                        </label>
                        <validation-provider #default="{ errors }" name="El Límite de prestamo es necesario"
                        rules="required">
                        <b-input-group>
                          <b-form-input id="v-loginFieldEmail" v-model="user.establishment_attributes.loan_balance_limit"
                          :state="errors.length > 0 ? false : null" placeholder="0" size="lg" />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  -->
                </b-row>
              </validation-observer>
            </tab-content>

            <!-- Dirección -->
            <tab-content title="Dirección" :before-change="validationDirForm">
              <validation-observer ref="accountDirRules" tag="form">
                <b-row>
                  <b-col cols="12" class="mb-2">
                    <small class="text-warning">
                      La siguiente dirección será utilizada para pedidos
                      realizados en línea. Recuerda que posteriormente puede ser
                      modificada. Si no encuentras tu calle, escribe el nombre
                      de tu ciudad y cuando el mapa este en tu ciudad, presiona
                      en tu ubicación
                    </small>
                  </b-col>
                  <b-col cols="12">
                    <!-- TODO: Add the element to search the directionon the google maps -->
                    <!-- <address-form-model info-type @getAddress="setAddressForEdit($event)" /> -->
                    <gmap-autocomplete
                      ref="googleAddress"
                      class="mb-2"
                      :componentRestrictions="{ country: 'mx' }"
                      placeholder="Escribe tu dirección"
                      @place_changed="setAddressForEdit($event)"
                    />
                    <GmapMap
                      @click="setMarker($event)"
                      :center="center"
                      :zoom="zoom"
                      map-type-id="roadmap"
                      class="gmap"
                    >
                      <GmapMarker
                        :position="marker"
                        :clickable="true"
                        :draggable="false"
                        @update="changeMarker($event)"
                      />
                    </GmapMap>
                  </b-col>
                </b-row>
              </validation-observer>
            </tab-content>

            <!-- Resumen de información -->
            <tab-content title="Confirmar">
              <b-row>
                <b-col>
                  <h2>Resumen de la información</h2>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="py-1">
                  <div class="mb-1">
                    <p class="mb-0 text-muted">Nombre:</p>
                    <p class="display-5">
                      {{ user.name }}
                    </p>
                  </div>

                  <div class="mb-1">
                    <p class="mb-0 text-muted">Correo:</p>
                    <p class="display-5">
                      {{ user.email }}
                    </p>
                  </div>
                  <div class="mb-1">
                    <p class="mb-0 text-muted">Celular:</p>
                    <p class="display-5">
                      {{ user.phone_number }}
                    </p>
                  </div>

                  <div class="mb-1">
                    <p class="d-flex flex-column">
                      <span class="text-muted"> Calle y número : </span>
                      {{
                        user.establishment_attributes.address_attributes.street
                      }}, #{{
                        user.establishment_attributes.address_attributes
                          .ext_number
                      }}
                    </p>
                  </div>

                  <div class="mb-1">
                    <p class="d-flex flex-column">
                      <span class="text-muted"> Código Postal: </span>
                      {{
                        user.establishment_attributes.address_attributes
                          .postal_code
                      }}
                    </p>
                  </div>

                  <div class="mb-1">
                    <p class="d-flex flex-column">
                      <span class="text-muted"> País y cuidad: </span>
                      {{
                        user.establishment_attributes.address_attributes
                          .country
                      }},
                      {{
                        user.establishment_attributes.address_attributes.state
                      }},{{
                        user.establishment_attributes.address_attributes.city
                      }}
                    </p>
                  </div>
                </b-col>
              </b-row>
            </tab-content>
          </form-wizard>
        </b-overlay>
      </b-col>
    </b-row>
  </main>
</template>

<script>
import { mapActions } from "vuex"
import { FormWizard, TabContent } from "vue-form-wizard"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import AddressFormModel from "@/@core/components/CustomerAddressFormModel.vue"
import { getGoogleMapsAPI } from "gmap-vue"

import store from "@/store/index"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

// Styles
import "@/@core/scss/vue/libs/vue-wizard.scss"
import "vue-form-wizard/dist/vue-form-wizard.min.css"
import { HomeIcon } from "vue-feather-icons"

export default {
  components: {
    FormWizard,
    TabContent,
    HomeIcon,

    ValidationProvider,
    ValidationObserver,
    AddressFormModel,
  },
  data() {
    return {
      whatsappNumber: "",
      user: {
        name: null,
        email: null,
        phone_number: null,
        sideImg: require("@/assets/images/pages/login-v2.svg"),
        establishment_attributes: {
          name: "",
          address_attributes: {},
          contact_name: "",
          contact_email: "",
          is_balance_shared: "",
          fiscal_id: "",
          bank_unique_id: "",
          cel_number: "",
          establishment_type: "",
          loan_balance_limit: "",
        },
      },
      loading: false,
      marker: {},
      center: { lat: 25.6866142, lng: -100.3161126 },
      zoom: 10,
      establishment_types: [
        { value: "car_accessories", text: "Accesorios para autos" },
        { value: "coffee_shop", text: "Cafetería" },
        { value: "butchery", text: "Carniceria" },
        { value: "locksmith", text: "Cerrajería" },
        { value: "corporate_education", text: "Educación corporativa" },
        { value: "drug_store", text: "Farmacia" },
        { value: "hardware_store", text: "Ferretería" },
        { value: "flower_store", text: "Librería" },
        { value: "liquor_store", text: "Licorería" },
        { value: "car_wash", text: "Lavado de autos" },
        { value: "car_tires", text: "Llantas y rines" },
        { value: "furniture_store", text: "Mueblería" },
        { value: "lumber_store", text: "Maderería" },
        { value: "outdoor_furniture_store", text: "Muebles de jardín" },
        { value: "optician", text: "Óptica" },
        { value: "office_supply_store", text: "Papelería" },
        { value: "water_purifier", text: "Purificadora de agua" },
        { value: "car_parts", text: "Refaccionaria" },
        { value: "restaurant", text: "Restaurante" },
        { value: "school", text: "Escuela" },
        { value: "gym", text: "Gimnasio" },
        { value: "health_food_store", text: "Tienda de alimentos saludables" },
        { value: "clothing_store", text: "Tienda de ropa" },
        { value: "home_goods_store", text: "Tienda de artículos para el hogar" },
        { value: "paint_store", text: "Tienda de pinturas" },
        { value: "music_store", text: "Tienda de música" },
        { value: "pet_store", text: "Tienda de mascotas" },
        { value: "groceries", text: "Tiendita de la esquina" },
        { value: "tortilleria", text: "Tortillería" },
        { value: "jewelry_store", text: "Joyería" },
        { value: "car_sales", text: "Venta de autos" },
        { value: "other", text: "Otra" },
      ],
      is_supplier_options: [
        { value: "supplier", text: "Si" },
        { value: "not_supplier", text: "No" },
      ],
    }
  },
  mounted() {},
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg")
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    ...mapActions("users", ["registerAsEstablishment"]),
    getAddress(place) {
      const address = {
        name: "",
        state: "",
        country: "",
        city: "",
        street: "",
        ext_number: "",
        int_number: "",
        postal_code: "",
        lat: "",
        lng: "",
        suburb: "",
        url: "",
        formatted_address: "",
      }
      place.address_components.forEach((x) => {
        if (x.types.includes("administrative_area_level_1")) {
          address.state = x.long_name
        }
        if (x.types.includes("country")) {
          address.country = x.long_name
        }
        if (x.types.includes("city") || x.types.includes("locality")) {
          address.city = x.long_name
        }
        if (x.types.includes("street") || x.types.includes("route")) {
          address.street = x.long_name
        }
        if (
          x.types.includes("ext_number") ||
          x.types.includes("street_number")
        ) {
          address.ext_number = x.long_name
        }
        if (x.types.includes("postal_code")) {
          address.postal_code = x.long_name
        }
        if (
          x.types.includes("suburb") ||
          x.types.includes("sublocality_level_1")
        ) {
          address.suburb = x.long_name
        }
      })
      address.formatted_address = place.formatted_address
      address.lat = place.geometry.location.lat()
      address.lng = place.geometry.location.lng()
      address.url = place.url
      return address
    },
    setMarker(event) {
      // goecode manual marker to get address address
      // eslint-disable-next-line
      const mapsapi = new getGoogleMapsAPI()
      // console.log(mapsapi.maps.Geocoder.prototype.geocode);
      // const geocoder = new google.gmapApi().maps.Geocoder
      const latlng = { lat: event.latLng.lat(), lng: event.latLng.lng() }
      mapsapi.maps.Geocoder.prototype.geocode(
        { location: latlng },
        (results, status) => {
          if (status === "OK") {
            if (results[1]) {
              this.$refs.googleAddress.$el.children[0].value =
                results[1].formatted_address
              this.user.establishment_attributes.address_attributes =
                this.getAddress(results[1])
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Introduzca la direccion manualmente",
                  icon: "EditIcon",
                  variant: "danger",
                },
              })
            }
          }
        }
      )
      const lat = event.latLng.lat()
      const lng = event.latLng.lng()
      this.user.establishment_attributes.google_maps_url = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
      this.marker = {
        lat,
        lng,
      }
    },
    setAddressForEdit(place) {
      this.center = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      }
      this.zoom = 16
      this.marker = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      }
      this.user.establishment_attributes.address_attributes =
        this.getAddress(place)
    },
    async formSubmitted() {
      this.loading = true
      this.user.establishment_attributes.contact_name = this.user.name
      this.user.establishment_attributes.contact_email = this.user.email
      this.user.establishment_attributes.loan_balance_limit = "0.0"
      this.user.establishment_attributes.cel_number = `${52}${
        this.whatsappNumber
      }`

      const establishment = {
        ...this.user,
        phone_number: `${52}${this.user.phone_number}`,
      }

      this.registerAsEstablishment(establishment)
        .then(() => {
          this.$swal({
            title: "¡Establecimiento registrado exitosamente!",
            text: "Revisa tus mensajes de texto para terminar tu registro.",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          })
          this.$router.push({ name: "home" })
        })
        .catch((error) => {
          // Corregido el manejo de catch
          if (error.response && error.response.data.messages) {
            this.$swal({
              title: "Error!",
              text: error.response.data.messages[0],
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            })
          }
        })
        .finally(() => {
          // Corregido el manejo de finally
          this.loading = false
        })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationCelForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountCelRules.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationDirForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountDirRules.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.wizard-icon-container {
  border-radius: 6px !important;
  background: red;
}

.wizard-nav.wizard-nav-pills {
  display: none;
}

.pac-target-input {
  color: #b4b7bd;
  background-color: #283046;
  border: 1px solid #404656;

  padding: 0.438rem 1rem;
  border-radius: 0.357rem;
  width: 100%;
}
</style>

<style lang="scss" scoped>
.establishment__form__container {
  height: 100vh;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 768px) {
    display: block;
  }
}

.gmap {
  height: 300px;
  width: 100%;
  border-radius: 0.357rem;
}

.home__icon {
  z-index: 999;
  color: #7367f0;
  position: absolute;
  top: 2rem;
  left: 2rem;
  cursor: pointer;

  @media (max-width: 768px) {
    position: static;
    margin-bottom: 1rem;
  }
}
</style>
